<template>
  <section>
    <section class="wrapper">
      <img src="@/assets/images/project.png" style="width: 30px;margin: 0 10px;">
      <div class="projectName">
        <div style="display: inline-block;">
          <span class="name">{{ projectName }}</span>
          <hr style="width: 10px;border: 1px solid #409EFF;margin: 10px auto;">
        </div>
        <el-button type="primary" size="mini" class="change" @click="changeProgect" :hidden="!isEnable">更换</el-button>
      </div>
      <span class="menuName">{{menu}}</span>
    </section>
    <el-dialog
      title="项目选择"
      :visible.sync="dialogVisible"
      top="30vh"
      width="30%"
      center>
      <el-form :model="form">
        <el-form-item label="项目名" >
          <!-- <el-input v-model="form.project" placeholder="请输入您想搜索的项目"></el-input> -->
          <el-select v-model="project" value-key="id" filterable placeholder="请输入您想搜索的项目">
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.customerName"
              :value="item">
              {{ item.customerName }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import eventHub from '@/utils/eventHub'
import { listCanSwitchCustomers, projectswitching, isHaveSwitchPermission } from '@/api/settings/project'
export default {
  created () {
    if (this.$parent.$data.title) {
      this.menu = this.$parent.$data.title
    } else {
      eventHub.$on('msg', value => {
        this.menu = value
      })
    }
  },
  beforeDestroy () {
    eventHub.$off('msg')
  },
  data () {
    return {
      dialogVisible: false,
      projectName: '请选择',
      menu: '',
      project: {},
      form: {
        project: '',
        cId: 0
      },
      projectList: [],
      isEnable: false
    }
  },
  methods: {
    async listAllCus () {
      const res = await listCanSwitchCustomers()
      res.data.forEach((item,index)=>{
        if(item.id === this.$route.params.id){
          this.projectName = item.customerName
        }
      })
      this.projectList = res.data
    },
    async permission () {
      const res = await isHaveSwitchPermission()
      console.log(res)
      this.isEnable = res.data.permission
      if (res.data.customerName) {
        this.projectName = res.data.customerName
      }
    },
    changeProgect () {
      this.dialogVisible = true
    },
    confirm () {
      this.projectName = this.project.name
      this.dialogVisible = false
      for (var i = 0; i < this.projectList.length; i++) {
        if (this.projectList[i].id === this.project.id + '') {
          this.projectName = this.projectList[i].customerName
          break
        }
      }
      this.form.cId = this.project.id
      this.switchProject()
    },
    async switchProject () {
      const res = await projectswitching(this.form)
      if (res.code === 0) {
        this.$store.dispatch('app/setProject', this.project)
        eventHub.$emit('cid', this.form.cId)
        window.refresh()
      }
    }
  },
  mounted () {
    this.permission()
    this.listAllCus()
  }
}
</script>

<style  lang="scss" scoped>
  .wrapper{
    width: 100%;
    height: 60px;
    background: #fff;
    border-radius: 2px;
    padding: 16px 5px;
    margin-bottom: 20px;
    .projectName{
      display: inline-block;
      vertical-align: top;
      height: 100%;
      span.name{
        color: #409EFF;
        font-size: 20px;
      }
      .change{
        padding: 3px;
        margin: 0 15px;
      }
    }
    .menuName{
      display: inline-block;
      border-left: 2px solid #ccc;
      padding-left: 15px;
      margin: 5px;
      vertical-align: top;
    }
    .el-dialog__body .el-input__suffix{
      display: none;
    }
  }
</style>
